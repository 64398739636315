/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { jsx } from 'theme-ui';
import React from 'react';
import FehlerHausverkaufForm from '../../components/Forms/FehlerHausverkaufForm';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { graphql, Link } from 'gatsby';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Text_Image from '../../components/raw_templates/Text_Image';

const Terminbuchung = ({ location, data }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <div sx={{ position: 'relative' }}>
            <Link
              sx={{
                position: 'sticky',
                float: 'right',
                top: 0,
                zIndex: 3,
                color: 'primary',
              }}
              to={closeTo}
            >
              <i className="fad fa-window-close fa-2x"></i>
            </Link>
            <Content location={location} hero={data.hero} />
          </div>
        ) : (
          <HomePageLayout
            location={location}
            lang="de"
            title="Die 10 häufigsten Fehler beim Hausverkauf"
            description="Ein Hausverkauf kann ziemlich nervenaufreibend sein. In einem Booklet verraten wir Ihnen, wie Sie die 10 häufigsten Fehler vermeiden können."
            image={data.hero.childImageSharp.fluid}
            keywords={['Hausverkauf', 'Fehler', 'Booklet']}
            header={{
              attachment: 'default',
              appearance: 'default',
              fluid: true,
            }}
          >
            <Content
              location={location}
              hero={data.hero}
              booklet={data.booklet}
            />
          </HomePageLayout>
        )}
      </div>
    )}
  </ModalRoutingContext.Consumer>
);

const Content = ({ hero, booklet, location }) => (
  <>
    <Text_Image
      background="white"
      image={hero}
      title="Diese Fehler können<br/> Sie beim Hausverkauf jetzt vermeiden!"
      text="
      <p>
        Ein Hausverkauf kann langwierig und oft ziemlich nervenaufreibend sein.
        Damit das nicht passiert, ist die richtige Vorbereitung maßgebend für den Erfolg des
        Immobilienverkaufs.
      </p>
      <p>
        In unserem Booklet verraten wir Ihnen <strong>die 10 häufigsten Fehler, die Hausverkäufer machen</strong>
        und wie Sie diese schnell und einfach vermeiden können.
      </p>
        "
    />
    <Section
      sx={{
        maxWidth: [null, null, 500, 760, 1100],
        marginX: 'auto',
        marginBottom: 7,
        boxShadow: `0 0 30px rgba(0,0,0,0.1)`,
        backgroundColor: '#fff',
      }}
    >
      <FehlerHausverkaufForm location={location} booklet={booklet} />
    </Section>
  </>
);

export default Terminbuchung;

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-zehn-fehler" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    booklet: file(
      name: {
        eq: "WirkaufendeinHaus - Die 10 häufigsten Fehler beim Hausverkauf"
      }
    ) {
      publicURL
    }
  }
`;
