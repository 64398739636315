/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import React from 'react';
import * as Yup from 'yup';
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Link from '@fify.at/gatsby-theme-base/src/components/Link/Link';

export default function FehlerHausverkaufForm({ location, booklet, ...rest }) {
  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return (
      <div>
        <Heading
          sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'left' }}
        >
          Fast geschafft.
        </Heading>
        <Alert variant="success">
          Vielen Dank für Ihre Anforderung unseres Booklets "Die 10 häufigsten
          Fehler beim Hausverkauf!
          <br />
          <br />
          Sie erhalten das Booklet in Kürze per E-Mail als Download Link. Bitte
          prüfen Sie Ihr Postfach inklusive Spam oder Junk Ordner!
          <br />
          <br />
          Sollten Sie dazu Fragen haben, können Sie uns jederzeit gerne{' '}
          <Link to="/kontakt/">kontaktieren</Link>.
        </Alert>
        {/*<Button variant="primary" href={Booklet} download>*/}
        {/*  Booklet jetzt herunterladen*/}
        {/*</Button>*/}
      </div>
    );
  }
  return (
    <ControlledForm
      name="fehler-hausverkauf-booklet"
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        booklet: location.origin + encodeURI(booklet.publicURL),
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <input type="hidden" name="booklet" />
      <Row>
        <Col>
          <Heading
            sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'left' }}
          >
            So können Sie unser kostenloses Booklet anfordern:
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Bitte geben Sie uns die nachfolgenden Daten bekannt und bestätigen
            Sie Ihre Anfrage mit nur einem Klick auf den Bestellbutton. Sie
            erhalten daraufhin umgehend, unser Booklet per Link bereitgestellt.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4}>
          <TextField label="Vorname" name="firstName" required />
        </Col>
        <Col sm={12} lg={4}>
          <TextField label="Nachname" name="lastName" required />
        </Col>
        <Col sm={12} lg={4}>
          <TextField label="E-Mail" name="email" required required />
        </Col>
      </Row>
      <SubmitButton
        onClick={function() {
          if (typeof fbq !== 'undefined') {
            fbq('track', 'Lead');
          }
        }}
      >
        Kostenloses Booklet jetzt anfordern <i class="fal fa-arrow-right"></i>
      </SubmitButton>
      <p
        sx={{
          marginY: '3',
        }}
      >
        <em>
          Datenschutz ist uns wichtig! Nähere Informationen zur Verwendung der
          von Ihnen bekanntgebenen Daten finden Sie{' '}
          <Link to="/datenschutzerklaerung/">hier</Link>.
        </em>
      </p>
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  lastName: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  email: Yup.string()
    .trim()
    .email('Bitte eine gültige E-Mail-Adresse eingeben')
    .required('Dieses Feld ist verpflichtend'),
});
